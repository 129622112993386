
































import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { dispatchcreateAppForUser } from '@/store/devs/actions';

@Component
export default class CreateApp extends Vue {
  public valid = false;
  public devAppName: string = '';
  public description: string = '';
  public isActive: boolean = false;
  public isLive: boolean = false;
  public appName = appName;

  public async mounted() {
    this.reset();
  }

  public reset() {
    this.devAppName = '';
    this.description = '';
    this.isActive = false;
    this.isLive = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    // if (await this.$validator.validateAll()) {
    // }
    const newApp = {
        name: this.devAppName,
        description: this.description,
        is_active: this.isActive,
        is_live : this.isLive,
    };
    await dispatchcreateAppForUser(this.$store, newApp);
    this.$router.push('/main/devs/apps/all');
  }
}
